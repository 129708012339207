import request from '@/utils/request'

export const pageList: any = (data: any) => {
    return request({
        url: `/msgOssTemplate/pageList`,
        method: 'post',
        data
    })
}

export const toggleEnableStatus: any = (id: string) => {
    return request({
        url: `/msgOssTemplate/toggleEnableStatus/${id}`,
        method: 'put',
    })
}
